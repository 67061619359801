<template>
  <div>
    <div class='flex flex-row justify-center h-screen'>
      <img :src='logoURL' class='w-40' />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex' 

export default {
  name: 'DrilldownContainer',
  data () {
    return {
      logoURL: process.env.VUE_APP_CLIENT_BASE_URL + '/logo_reach_horizontal.svg'
    }
  },
  watch: {
    showSidepanel: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && !newVal) {
          // when the sidepanel is closed, push to a different page
          this.goHome()
        }
      }
    }
  },
  computed: {
    ...mapState([
      'showSidepanel'
    ]),
  },
  methods: {
    ...mapActions([
      'setSidepanelFullscreen'
    ]),
    ...mapActions('investments', [
      'openInvestmentDrilldown'
    ]),
    ...mapActions('dashboardViews', [
      'getIndividualDashboardView'
    ]),
    goHome () {
      this.$router.push({ name: 'Dashboard' })
    }
  },
  mounted () {
    // check query params
    if (this.$route.query.investment_id) {
      let investmentId = parseInt(this.$route.query.investment_id)
      this.setSidepanelFullscreen()
      this.getIndividualDashboardView(localStorage.getItem('lastDashboardViewId')).then(() => {
        this.openInvestmentDrilldown(investmentId)
      })
      
    } else {
       this.goHome()
    }
  }
}
</script>
